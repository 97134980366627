export const APPLICATION = {
	DECLINED: 'DECLINED',
	ABORTED: 'ABORTED',
	ABORT_REQUESTED: 'ABORT_REQUESTED',
	APPLIED: 'APPLIED',
	ACCEPTED: 'ACCEPTED',
}


export const PAYMENT = {
	UNDEFINED: 'UNDEFINED',
	REMINDER_SENT: 'REMINDER_SENT',
	PAYED: 'PAYED',
}


export const ATTENDANCE = {
	UNATTENDED: 'UNATTENDED',
	UNDEFINED: 'UNDEFINED',
	ATTENDED: 'ATTENDED',
}