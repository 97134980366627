<template>
	<main class="pt-0">
		<section>
			<BaseSearchInput v-model="searchText" placeholder="Sök namn eller födelsedatum">
				Sök namn eller födelsedatum
			</BaseSearchInput>
		</section>

		<section class="py-0" v-if="payments">
			<div class="flex items-center justify-between gap-5 mb-20">
				<div class="flex items-center gap-5">
					<BaseButton>
						Påminnelse skickad
					</BaseButton>
					<BaseButton>
						Ej betalt
					</BaseButton>
					<BaseButton>
						Betalt
					</BaseButton>
				</div>

				<BaseButton transparent>
					Exportera
				</BaseButton>
			</div>

			<table class="list-table">
				<tr>
					<th class="w-1/5">Namn</th>
					<th class="w-1/5">Anmäld av</th>
					<th class="w-1/5">Födelsedatum</th>
					<th class="w-1/5">Pris</th>
					<th class="w-1/5">Status</th>
				</tr>

				<tr v-for="payment in payments" :key="payment.id">
					<td>{{ payment.first_name }} {{ payment.last_name }}</td>
					<td v-if="payment.applicant">{{ payment.applicant.first_name }} {{ payment.applicant.last_name }}</td>
					<td v-else>-</td>
					<td>{{ payment.birthday }}</td>
					<td v-if="payment.price">{{ payment.price }} kr</td>
					<td v-else>Gratis</td>
					<td :class="paymentStatusColor(payment.status)">{{ paymentStatus(payment.status) }}</td>
				</tr>
			</table>
		</section>
	</main>
</template>

<script>
import basicApiCall from '@/core/helpers/basicApiCall'
import ApplicationService from '@/core/services/ApplicationService'
import {PAYMENT} from '@/core/types/ActivityTypes'

export default {
	name: 'ActivityPresence',
	data: () => ({
		searchText: '',
		payments: null,
	}),
	methods: {
		paymentStatus(status) {
			switch (status) {
				case PAYMENT.PAYED:
					return 'Betald'
				case PAYMENT.REMINDER_SENT:
					return 'Påminnelse skickad'
				case PAYMENT.UNDEFINED:
					return '-'
			}
		},
		paymentStatusColor(status) {
			switch (status) {
				case PAYMENT.PAYED:
					return 'text-green-500'
				case PAYMENT.REMINDER_SENT:
					return 'text-red-500'
				case PAYMENT.UNDEFINED:
					return '-'
			}
		},
		async fetchPayment() {
			const {status, data} = await basicApiCall(ApplicationService.indexPayment(this.$route.params.id))
			if (status === 200) {
				this.payments = data
			}
		},
	},
	created() {
		this.fetchPayment()
	},
}
</script>

<style scoped>

</style>